<template>
  <section class="container my-4">
    <h4 class="my-4">Relatório <strong>financeiro</strong> com filtro</h4>

    <div class="alert alert-info my-3" v-if="filterTitle">
      {{ filterTitle }}
    </div>

    <form @submit.prevent="generateFile">
      <div class="row">
        <div class="form-group col-md-12">

          <div class="row">
            <!-- Campo Tipo de Arquivo -->
            <div class="col-md-2">
              <label for="type_print" class="form-label">Gerar no formata:</label>
              <div>
                <input
                  type="radio"
                  id="type_print_xlsx"
                  value="xlsx"
                  v-model="form.type_print"
                  class="form-check-input"
                />
                <label for="type_print_xlsx" class="form-check-label">📊 XLSX</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="type_print_pdf"
                  value="pdf"
                  v-model="form.type_print"
                  class="form-check-input"
                />
                <label for="type_print_pdf" class="form-check-label">📄 PDF</label>
              </div>
            </div>
          </div>
          <hr>
          <div class="row">
            <!-- Campo Turma -->
            <div class="col-md-2">
              <div class="mb-3">
                <label for="team" class="form-label">Por turma:</label>
                <select
                  v-model="form.team"
                  class="form-control form-control-sm"
                  id="selectTeam"
                >
                  <option value="" selected disabled>-- Selecione --</option>
                  <option :value="team.id" v-for="team in teams" :key="team.id">
                    {{ team.name | uppercase }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Campo Registro -->
            <div class="col-md-2">
              <label for="registration_id" class="form-label">Por matrícula:</label>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="form.registration_id"
              />
            </div>

            <!-- Campo Registro -->
            <div class="col-md-2">
              <label for="student_id" class="form-label">Por ID:</label>
              <input
                type="text"
                placeholder="ID do estudante"
                class="form-control form-control-sm"
                v-model="form.student_id"
              />
            </div>

            <!-- Campo Nome do Aluno -->
            <div class="col-md-2">
              <label for="student_name" class="form-label">Por nome do aluno:</label>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="form.student_name"
                placeholder="Digite parte do nome"
              />
            </div>

            <!-- Campo Cota -->
            <div class="col-md-2">
              <label for="quota" class="form-label">Por parcela:</label>
              <input
                type="number"
                class="form-control form-control-sm"
                v-model="form.quota"
                placeholder="Ex.: 01"
              />
            </div>        

            <!-- Campo Usuário -->
            <div class="col-md-2">
              <label for="user_id" class="form-label">Por usuário criador:</label>
              <select
                v-model="form.user_id"
                class="form-control form-control-sm"
              >
                <option value="" selected disabled>-- Selecione --</option>
                <option :value="user.id" v-for="user in users" :key="user.id">
                  {{ user.name }}
                </option>
              </select>
            </div>

            <!-- Campo Usuário Atualizado -->
            <div class="col-md-2">
              <label for="updated_user_id" class="form-label">Por usuário atualizador:</label>
              <select
                v-model="form.updated_user_id"
                class="form-control form-control-sm"
              >
                <option value="" selected disabled>-- Selecione --</option>
                <option :value="user.id" v-for="user in users" :key="user.id">
                  {{ user.name }}
                </option>
              </select>
            </div>

            <!-- Campo Pago -->
            <div class="col-md-2">
              <label for="paid" class="form-label">Pago:</label>
              <select
                v-model="form.paid"
                class="form-control form-control-sm"
              >
                <option value="" selected disabled>-- Selecione --</option>
                <option value="1">Sim</option>
                <option value="0">Não</option>
              </select>
            </div>

            <!-- Campo Pago Por -->
            <div class="col-md-2">
              <label for="paid_by_user_id" class="form-label badge bg-secondary">Dado baixa por:</label>
              <select
                v-model="form.paid_by_user_id"
                class="form-control form-control-sm"
              >
                <option value="" selected disabled>-- Selecione --</option>
                <option :value="user.id" v-for="user in users" :key="user.id">
                  {{ user.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <!-- Campo Tipo de Serviço -->
            <div class="col-md-4">
              <label for="service_type_id" class="form-label">Tipo de serviço:</label>
              <select
                v-model="form.service_type_id"
                class="form-control form-control-sm"
              >
                <option value="" selected disabled>-- Selecione --</option>
                <option :value="service.id" v-for="service in services" :key="service.id">
                  {{ service.name }}
                </option>
              </select>
            </div>

             <!-- Campo Tipo de Pagamento -->
             <div class="col-md-4">
              <label for="payment_type_id" class="form-label">Por tipo de pagamento:</label>
              <select
                v-model="form.payment_type_id"
                class="form-control form-control-sm"
              >
                <option value="" selected disabled>-- Selecione --</option>
                <option :value="payment.id" v-for="payment in paymentTypes" :key="payment.id">
                  {{ payment.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <!-- Campo Valor Inicial -->
            <div class="col-md-4">
              <label for="initial_value" class="form-label">Por range de valores:</label>
              <input
                type="number"
                class="form-control form-control-sm"
                v-model="form.initial_value"
              />
            </div>

            <!-- Campo Valor Final -->
            <div class="col-md-4">
              <label for="final_value" class="form-label">--</label>
              <input
                type="number"
                class="form-control form-control-sm"
                v-model="form.final_value"
              />
            </div>
          </div>

          <div class="row">
            <!-- Campo Período -->
            <div class="col-md-4">
              <label for="start_date" class="form-label">Por data de vencimento:</label>
              <input
                type="date"
                class="form-control form-control-sm"
                v-model="form.start_date"
              />
            </div>
            <div class="col-md-4">
              <label for="end_date" class="form-label">--</label>
              <input
                type="date"
                class="form-control form-control-sm"
                v-model="form.end_date"
              />
            </div>
          </div>

          <div class="row">
            <!-- Campo Data de Pagamento -->
            <div class="col-md-4">
              <label for="start_pay_day" class="form-label">Por data de pagamento:</label>
              <input
                type="date"
                class="form-control form-control-sm"
                v-model="form.start_pay_day"
              />
            </div>
            <div class="col-md-4">
              <label for="end_pay_day" class="form-label">--</label>
              <input
                type="date"
                class="form-control form-control-sm"
                v-model="form.end_pay_day"
              />
            </div>
          </div>

          <div class="row">
            <!-- Campo Data de Criação -->
            <div class="col-md-4">
              <label for="start_created_at" class="form-label">Por data de criação:</label>
              <input
                type="date"
                class="form-control form-control-sm"
                v-model="form.start_created_at"
              />
            </div>
            <div class="col-md-4">
              <label for="end_created_at" class="form-label">--</label>
              <input
                type="date"
                class="form-control form-control-sm"
                v-model="form.end_created_at"
              />
            </div>
          </div>

          <div class="row">

            <!-- Campo Observações -->
            <div class="col-md-8">
              <label for="observations" class="form-label">Por observações:</label>
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="form.observations"
                placeholder="Digite trecho completo ou parte da observação"
              />
            </div>
          </div>

          <hr>
          <div v-if="!loading" class="form-group text-center my-2">
            <button
              type="submit"
              class="btn btn-success btn-sm mx-2 text-uppercase"
            >
              Gerar
            </button>
            <button
              type="reset"
              class="btn btn-secondary btn-sm text-uppercase"
              @click="resetFilters"
            >
              Limpar
            </button>
          </div>

          <LoadingPage v-else />
        </div>
      </div>
    </form>

    <button class="btn btn-sm btn-secondary" @click="$router.go(-1)">
      VOLTAR
    </button>
  </section>
</template>

<script>
import api from "@/services";
import LoadingPage from "@/components/LoadingPage.vue";
import { generateNameFile } from "@/helpers";

export default {
  name: "DefaultersReport",
  components: {
    LoadingPage,
  },
  data() {
    return {
      teams: [],
      users: [],
      services: [],
      paymentTypes: [],
      //
      loading: false,
      form: {
        team: "",
        start_date: "",
        end_date: "",
        registration_id: "",
        student_id: "",
        quota: "",
        initial_value: "",
        final_value: "",
        paid: "",
        observations: "",
        service_type_id: "",
        payment_type_id: "",
        start_created_at: "",
        end_created_at: "",
        user_id: "",
        start_pay_day: "",
        end_pay_day: "",
        student_name: "",
        updated_user_id: "",
        paid_by_user_id: "",
        type_print: "xlsx",
      },
    };
  },
  computed: {
    filterTitle() {
      const filters = [];

      if (this.form.team) {
        const team = this.teams.find((t) => t.id === this.form.team);
        filters.push(`Turma: ${team ? team.name : "Selecionada"}`);
      }
      if (this.form.start_date && this.form.end_date) {
        filters.push(`Período: ${this.form.start_date} a ${this.form.end_date}`);
      }
      if (this.form.registration_id) {
        filters.push(`Matrícula: ${this.form.registration_id}`);
      }
      if (this.form.student_id) {
        filters.push(`Estudante ID: ${this.form.student_id}`);
      }
      if (this.form.student_name) {
        filters.push(`Aluno: ${this.form.student_name}`);
      }
      if (this.form.service_type_id) {
        const service = this.services.find((s) => s.id === this.form.service_type_id);
        filters.push(`Serviço: ${service ? service.name : "Selecionado"}`);
      }
      if (this.form.quota) {
        filters.push(`Parcela: ${this.form.quota}`);
      }
      if (this.form.initial_value && this.form.final_value) {
        filters.push(`Valores: ${this.form.initial_value} a ${this.form.final_value}`);
      }
      if (this.form.paid) {
        filters.push(`Pago: ${this.form.paid === "true" ? "Sim" : "Não"}`);
      }
      if (this.form.observations) {
        filters.push(`Observações: ${this.form.observations}`);
      }
      if (this.form.payment_type_id) {
        const paymentType = this.paymentTypes.find((p) => p.id === this.form.payment_type_id);
        filters.push(`Tipo de pagamento: ${paymentType ? paymentType.name : "Selecionado"}`);
      }
      if (this.form.start_created_at && this.form.end_created_at) {
        filters.push(`Criação: ${this.form.start_created_at} a ${this.form.end_created_at}`);
      }
      if (this.form.user_id) {
        const user = this.users.find((u) => u.id === this.form.user_id);
        filters.push(`Usuário criador: ${user ? user.name : "Selecionado"}`);
      }
      if (this.form.start_pay_day && this.form.end_pay_day) {
        filters.push(`Pagamento: ${this.form.start_pay_day} a ${this.form.end_pay_day}`);
      }
      if (this.form.updated_user_id) {
        const updatedUser = this.users.find((u) => u.id === this.form.updated_user_id);
        filters.push(`Usuário atualizador: ${updatedUser ? updatedUser.name : "Selecionado"}`);
      }
      if (this.form.paid_by_user_id) {
        const paidByUser = this.users.find((u) => u.id === this.form.paid_by_user_id);
        filters.push(`Dado baixa por: ${paidByUser ? paidByUser.name : "Selecionado"}`);
      }
      if (this.form.type_print) {
        filters.push(`Gerado no formato: ${this.form.type_print}`);
      }

      return filters.length > 0 ? `Filtros Selecionados: ${filters.join(", ")}` : "Nenhum filtro selecionado";
    },
  },
  methods: {
    resetFilters() {
      location.reload()
    },
    async getUsers() {
      await api.get(`/users?page=0&perPage=99999&sortBy=name&forPagination=1`).then((res) => {
        this.users = res.data.data;
      });
    },
    async getTeams() {
      await api.get(`/teams?page=0&perPage=99999&sortBy=name&forPagination=1`).then((res) => {
        this.teams = res.data.data;
      });
    },
    async getServices() {
      await api.get(`/service-types?page=0&perPage=99999&sortBy=name&forPagination=1`).then((res) => {
        this.services = res.data.data;
      });
    },
    async getPaymentTypes() {
      await api.get(`/payment-types?page=0&perPage=99999&sortBy=name&forPagination=1`).then((res) => {
        this.paymentTypes = res.data.data;
      });
    },
    async generateFile() {

      try {
        this.loading = true;

        // Construindo a query string dinamicamente
        const queryString = Object.entries(this.form)
          .filter(([key, value]) => value) // Filtra apenas os campos com valores definidos
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`) // Codifica os parâmetros
          .join("&");

        const url = `/exports/report-financial-with-filter?${queryString}`;

        const response = await api.get(url, { responseType: "blob" });

        const fileType = this.form.type_print === "pdf"
          ? "application/pdf"
          : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

        const fileExtension = this.form.type_print === "pdf" ? "pdf" : "xlsx";

        const blob = new Blob([response.data], { type: fileType });

        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = generateNameFile(`RelatorioFinanceiroComFiltro`) + `.${fileExtension}`;
        a.click();
      } catch (error) {
        console.error("Erro ao gerar o arquivo:", error);
        Toast.fire("Erro", "Não foi possível gerar o arquivo. Verifique os logs.", "error");
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getTeams();
    this.getUsers();
    this.getServices();
    this.getPaymentTypes();
  },
};
</script>

<style scoped></style>
